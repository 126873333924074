<template>
    <div class="refund" >
<!-- <van-nav-bar title="退款" left-text="返回" left-arrow  :border="false" safe-area-inset-top @touchmove.prevent>
  <template #left >
    <img class="back" src="../../assets/img/commoditySpike/back.png" alt="" @click="$goBack()">
  </template>
  <template #right>
    <img class="back" src="../../assets/img/more.png" alt="">
  </template>
</van-nav-bar> -->
<Head :type="0">
    <template #title>
        <div>申请退款</div>
    </template>
</Head>
<div class="main">
    <div class="list">
<van-radio-group v-model="radio" v-if="orderList.length" >
  <van-radio  :name= "index" v-for="item , index  in  orderList" :key="item.id" style="width:100%" :disabled=" Boolean(item.status - 0 ) || !Boolean(item.paymentPrice) ">
      <div class="wrap">
      <img class="img" :src="item.picUrl" alt="">
      <div class="good-box">
          <div class="name">{{item.spuName}}</div>
          <div class="sub"> 规格：{{item.specInfo||'无'}}   <span style="float:right;">X{{item.quantity}}</span></div>
      </div>
      <div class="price">￥<span>{{item.paymentPrice}}</span> </div>
      </div>
  </van-radio>

  
</van-radio-group>
</div>
<ul>
    <li>
        <div class="title">
            退款原因
        </div>
        <div class="content" @click="show=true" style="color:#999;">{{reszon}}</div>
        <img src="../../assets/img/right_gery.png" alt="">
    </li>
    <li>
        <div class="title">联系人</div>
        <div class="content" v-if="$store.state.userDate">{{$store.state.userDate.nickName}}</div>
    </li>
     <li>
        <div class="title">联系方式</div>
        <div class="content" v-if="$store.state.userDate">{{$store.state.userDate.phone}}</div>
    </li>
</ul>
</div>
<div class="footer" @touchmove.prevent>
    <div  class="btn"  @click="tuikuan()"> 申请退款</div>
</div>
<van-action-sheet v-model="show" >
  <div class="box">
      <div class="title">请选择取消订单的原因（必选）</div>
      <div class="choose">
          <div class="choose-item" v-for="item in chooseList" :key="item" @click="reszon=item,show=false">
              <img src="../../assets/roomImg/choose.png" v-if="item!=reszon" alt="">
              <img src="../../assets/chooseRead.png" v-if="item==reszon" alt="">

              <div>{{item}}</div>
          </div>
      </div>
      <div class="bottom" @click="show=false">
          确定
      </div>
  </div>
</van-action-sheet>

    </div>
</template>
<script>
import Head from '../../components/head/index.vue'
import { getMoney} from '../../api/refund/index'
import { getOrderDetail } from "@/api/orderDetail";
    export default {
        data(){
            return{
                startY:0,
                show:false,
                reszon:"请选择退款原因",
                chooseList:[ '商品无货','发货时间问题','不想要了','商品错选/多选','地址信息填写错误','商品降价','没用/少用/错用优惠'],
                userInfo:null,
                radio:null,
                orderList:[]
            }
        },
       
            mounted(){
                 getOrderDetail(this.$route.query.id).then(res => { 
                            if(res.data.code == 0) { 

                                this.orderList = res.data.data.listOrderItem
                            }else{ 
                                this.$toast(res.data.msg)
                            }
                    })
               this.getUser()
               console.log(this.$store.state);
                    this.setRem()
                    window.addEventListener('resize',this.setRem)
                   
                   
            },
            methods:{
                              
                    tuikuan(){

                        //  reszon:"请选择退款原因",
                         if(this.reszon==="请选择退款原因"){

                             this.$toast('退款原因不能为空')
                            
                                return

                         }

						if(this.radio == null){ 
							this.$toast('请选择需要退款的商品')
							return

						}

                            let re = {refundReson:this.reszon , 					//退款原因，退款时间戳
                                      refundTradeNo:Date.now(),
                                      status: '1', 
																			orderItemId:this.orderList[this.radio].id,
																			orderId : this.orderList[this.radio].orderId,
																			orderType :'0'
																			} 
                            
                                
                        
                // sessionStorage.setItem('status',JSON.stringify({ index:String(this.$route.query.index) ,attribute:this.$route.query.attribute,status:1}))
              getMoney(re).then(res=>{

                           if(res.data.code===0){

                               if(res.data){
                                   this.$toast.success('申请成功')
                                    

                                   this.$router.back()
                               }
                               
                           }else{ 
                               this.$toast(res.data.msg)
                           }
                       })     

                    }
            },
            components:{Head}
    }
</script>
<style lang="less" scoped>
.refund{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    font-family: PingFangSC-Medium, PingFang SC;
    display: flex;
    flex-direction: column;
    background-color: white;
    .back{
        width: .666667rem;
        height: .64rem;
    }
     .main{
         background-color: #f6f6f6;
        top: 1.333333rem;
        width: 100%;
        overflow: auto;
        flex: 1;
        
      
        ul{
           background-color: white;
        li{
            display: flex;
            height: 1.493333rem;
            border-bottom: 1px solid #eee;
            align-items: center;
            padding-right: .4rem;
                font-size: .453333rem;

            .title{
                width: 2.08rem;
                height: .8rem;
                border-right:1px solid rgba(0,0,0,.1) ;
                padding:0 .4rem;
                 display: flex;
                align-items: center;
                justify-content: center;
                
            }
            .content{
                flex: 1;
                display: flex;
                align-items: center;
                padding-left: .266667rem;
            }
            img{
                width: .4rem;
            }
            }
        }

    }
    .footer{
        width: 100%;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
        padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
        display: flex;
        align-items: center;
            .btn{
        width: 9.173333rem; 
        height: .96rem;
        background-image: linear-gradient(to right , #FF7200,#FF3C00);
        border-radius: .48rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: .373333rem;
        margin: .266667rem auto;
    }
    }
    .box{
        padding-top:.533333rem ;
         padding-bottom: constant(safe-area-inset-bottom);///兼容 IOS<11.2/
            padding-bottom: env(safe-area-inset-bottom);///兼容 IOS>11.2/
        .title{
            text-align: center;
            height: .586667rem;
            font-size: .426667rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            padding-bottom: .533333rem;
        }
        .choose-item{
            display: flex;
            align-items: center;
            height: 1.493333rem;
            border-bottom: .013333rem solid #eee;
            padding-left: .453333rem;
            img{
                width: .64rem;
                height: .64rem;
                margin-right: .4rem;
            }
            
        }
        .bottom{
            width: 9.173333rem;
            height: .96rem;
            background-image: linear-gradient(to right,#FF7200,#FF3C00);
            border-radius: .48rem;
            margin:.266667rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .373333rem;
            color: white;
        }
    }
    

   
}
.list{ 
    padding: .466667rem;
    background-color: white;
    margin: .266667rem 0;
.wrap{ 
    display: flex;
    align-items: center;
    padding: .133333rem 0;
    width: 100%;
    .good-box{ 
        display: flex;
        flex-direction: column;
        justify-content: space-between;
     height: 2.133333rem;
   
    .name{ 
        color: black;
        
    }
    .sub{ 
            color: #aaa;
        }
       
}
 .price{ 
            color: #A80000;
            margin-left: .4rem;
            padding-left: .266667rem;
           
            font-family: PingFangSC-Medium, PingFang SC;
            span{ 
                 font-size: .48rem;
            }
        }
 }
}
.img{ 
    width: 2.133333rem;
    height: 2.133333rem;
    border-radius: .266667rem;
    margin-right: .4rem;
}

</style>